import * as React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import NewsletterBanner from "../../components/newsletterBanner";
import DestinationsRow from "../../components/destinationsRow";

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Sterea Medicina e Viaggi" />

      <NewsletterBanner />
    </Layout>
  );
};

export default IndexPage;
